module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<table class="table table-sm">\n    <thead>\n        <tr>\n            <th>#</th>\n            <th></th>\n            <th>\n                物件名<br>\n                住所\n            </th>\n            <th>\n                販売状況<br>\n                内覧可否\n            </th>\n            <th class="clickable" data-col="price">価格</th>\n            <th class="clickable" data-col="acquisition_date">取得期日</th>\n            <th class="clickable" data-col="days_since_acquisition">取得日数</th>\n            <th class="clickable" data-col="next_update_date">次回更新日</th>\n            <th class="clickable" data-col="type">種別</th>\n            <th class="clickable" data-col="assigned_staff_1">担当</th>\n            <th class="clickable" data-col="brokerage_type">媒介</th>\n            <th class="clickable" data-col="size">面積</th>\n            <th class="clickable" data-col="tax_type">税金</th>\n            <th class="clickable" data-col="vacancy_status">現況</th>\n            <th>広告</th>\n        </tr>\n    </thead>\n    <tbody>\n        ';
 results.forEach((result, index) => { ;
__p += '\n        <tr class="motozuke-row">\n            <td>' +
((__t = ( index + 1 )) == null ? '' : __t) +
'</td>\n            <td class="text-center">\n                <a class="lightbox" href="' +
((__t = ( result.cover_photo_image )) == null ? '' : __t) +
'?w=1140&h=641&nc&ne&f=webp">\n                    <img src="' +
((__t = ( result.cover_photo_image )) == null ? '' : __t) +
'?w=100&h=77" loading="lazy">\n                </a>\n            </td>\n            <td>\n                <p class="mb-2">\n                    <a href="' +
((__t = ( Formatter.property_edit_url(result) )) == null ? '' : __t) +
'">' +
((__t = ( result.property_name )) == null ? '' : __t) +
'</a>\n                </p>\n                <p class="mb-0">' +
((__t = ( result.address )) == null ? '' : __t) +
'</p>\n            </td>\n            <td class="text-nowrap">\n                ' +
((__t = ( TextHelper.saleStatus(result.availability_status) )) == null ? '' : __t) +
'<br>\n                ' +
((__t = ( TextHelper._(result.viewing_type, 'viewing_type') )) == null ? '' : __t) +
'\n            </td>\n            <td class="text-right text-nowrap">' +
((__t = ( numeral(result.price / 10000).format('0,0') )) == null ? '' : __t) +
'万円</td>\n            <td class="text-nowrap">' +
((__t = ( result.acquisition_date )) == null ? '' : __t) +
'</td>\n            <td class="text-nowrap">' +
((__t = ( result.days_since_acquisition ? result.days_since_acquisition + '日' : '' )) == null ? '' : __t) +
'</td>\n            <td class="text-nowrap">' +
((__t = ( result.next_update_date )) == null ? '' : __t) +
'</td>\n            <td class="text-nowrap">' +
((__t = ( result.type )) == null ? '' : __t) +
'</td>\n            <td>\n            ';
 if (result.assigned_staff_1) { ;
__p += '\n                ' +
((__t = ( result.assigned_staff_1.last_name )) == null ? '' : __t) +
'<br>\n            ';
 } ;
__p += '\n            ';
 if (result.assigned_staff_2) { ;
__p += '\n                ' +
((__t = ( result.assigned_staff_2.last_name )) == null ? '' : __t) +
'\n            ';
 } ;
__p += '\n            </td>\n            <td>' +
((__t = ( result.brokerage_type )) == null ? '' : __t) +
'</td>\n            <td>\n                ' +
((__t = ( numeral(result.size).format('0.00') )) == null ? '' : __t) +
'm&sup2;<br>\n                ' +
((__t = ( numeral(result.size_tsubo).format('0.00') )) == null ? '' : __t) +
'坪\n            </td>\n            <td class="text-nowrap">' +
((__t = ( TextHelper._(result.tax_type, 'tax_type') )) == null ? '' : __t) +
'</td>\n            <td class="text-nowrap">' +
((__t = ( TextHelper._(result.vacancy_status, 'vacancy_status') )) == null ? '' : __t) +
'</td>\n            <td>\n            ';
 if (result.ad_channels.includes('agents')) { ;
__p += '\n                <span class="badge badge-warning">広告掲載</span><br>\n            ';
 } ;
__p += '\n            ';
 if (result.ad_channels.includes('suumo')) { ;
__p += '\n                <span class="badge badge-suumo">SUUMO</span><br>\n            ';
 } ;
__p += '\n            ';
 if (result.ad_channels.includes('yahoo')) { ;
__p += '\n                <span class="badge badge-yahoo">Yahoo!不動産</span><br>\n            ';
 } ;
__p += '\n            ';
 if (result.ad_channels.includes('edm')) { ;
__p += '\n                <span class="badge badge-edm">EDM</span><br>\n            ';
 } ;
__p += '\n            ';
 if (result.ad_channels.includes('sns')) { ;
__p += '\n                <span class="badge badge-sns">SNS</span><br>\n            ';
 } ;
__p += '\n            ';
 if (result.ad_channels.includes('reins')) { ;
__p += '\n                <span class="badge badge-reins">REINS</span><br>\n            ';
 } ;
__p += '\n            ';
 if (result.ad_channels.includes('rakumachi')) { ;
__p += '\n                <span class="badge badge-rakumachi">楽待</span><br>\n            ';
 } ;
__p += '\n\n            ';
 if (result.published === 1) { ;
__p += '\n                <span class="badge badge-hj">HJ:Published</span><br>\n            ';
 } else if (result.published === 2) { ;
__p += '\n                <span class="badge badge-hj">HJ:Private</span><br>\n            ';
 } ;
__p += '\n            </td>\n        </tr>\n        ';
 }); ;
__p += '\n    </tbody>\n</table>';

}
return __p
};
