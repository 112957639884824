import config from '@/js/app/config';
import { getFinancialYear } from '@/js/app/formatter';
import * as TextHelper from '@/js/app/text-helper';
import deepClone from '@/js/libs/deepClone';
import { View } from 'backbone';
import Chart from 'chart';
import moment from 'moment';
import companyTemplate from '../templates/results_company.html';
import divisionTemplate from '../templates/results_division.html';

export default class YtdResultsView extends View {
    initialize() {
        // When model changes; render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('ReportYtdResultsView#render');

        // Generate financial year period for chosen year
        const fyPeriod = TextHelper.fyPeriod(this.criteria.fy);

        const raw_data = deepClone(this.model.toJSON());

        const isCompany = raw_data.divisions != null;

        let decimalCount;
        // Format and summarize data
        if (isCompany) {
            decimalCount = 1;
            raw_data.divisions = this.formatAndSummarizeData(
                raw_data.divisions,
                decimalCount,
            );
        } else {
            decimalCount = 2;
            raw_data.staff = this.formatAndSummarizeData(
                raw_data.staff,
                decimalCount,
            );
        }

        // Calculate percentages
        raw_data.months.pct = [];
        for (let i = 0; i < 12; i++) {
            raw_data.months.pct[i] = raw_data.months.target[i]
                ? (
                      (raw_data.months.revenue[i] / raw_data.months.target[i]) *
                      100
                  ).toFixed(1)
                : null;
        }
        raw_data.months.pct_ytd = raw_data.months.target_ytd
            ? (
                  (raw_data.months.revenue_total / raw_data.months.target_ytd) *
                  100
              ).toFixed(1)
            : null;
        raw_data.months.pct_year = raw_data.months.target_total
            ? (
                  (raw_data.months.revenue_total /
                      raw_data.months.target_total) *
                  100
              ).toFixed(1)
            : null;

        // Format months data
        raw_data.months.revenue = raw_data.months.revenue.map((amount) =>
            (amount / 1000000).toFixed(decimalCount),
        );
        raw_data.months.revenue_total = (
            raw_data.months.revenue_total / 1000000
        ).toFixed(decimalCount);

        raw_data.months.target = raw_data.months.target.map((amount) =>
            (amount / 1000000).toFixed(decimalCount),
        );
        raw_data.months.target_total = (
            raw_data.months.target_total / 1000000
        ).toFixed(decimalCount);

        // Determine index of current month in data
        const now = moment();
        const currentFy = getFinancialYear();
        let currentPeriodIndex;
        if (this.criteria.fy == currentFy) {
            currentPeriodIndex =
                now.month() + 1 < 4 ? now.month() + 1 + 8 : now.month() + 1 - 4;
        }

        const templateOptions = {
            months: fyPeriod,
            results: raw_data,
            fy: this.criteria.fy,
            currentPeriodIndex: currentPeriodIndex,
            divisionId: this.criteria.division_id,
        };

        // Render template
        if (isCompany) {
            this.el.innerHTML = companyTemplate(templateOptions);
        } else {
            this.el.innerHTML = divisionTemplate(templateOptions);
        }

        // Create object for chart data
        const chart_data = {
            labels: fyPeriod.map((date) => date.format('MMM')),
            datasets: [],
        };

        // Add targets dataset
        chart_data.datasets.push({
            type: 'line',
            label: 'Target',
            data: raw_data.months.target,
            lineTension: 0,
            fill: false,
            borderColor: 'rgb(237,67,55)',
            backgroundColor: 'rgb(255,255,255)',
        });

        if (isCompany) {
            this.prepareChartData(chart_data, raw_data.divisions);
        } else {
            this.prepareChartData(chart_data, raw_data.staff);
        }

        // Create chart
        const ctx = this.$el.find('canvas.chart').get(0).getContext('2d');
        new Chart(ctx, {
            type: 'bar',
            data: chart_data,
            options: {
                easing: 'easeOutQuint',
                legend: {
                    position: 'bottom',
                },
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                        },
                    ],
                    yAxes: [
                        {
                            stacked: true,
                        },
                    ],
                },
            },
        });

        return this;
    }

    formatAndSummarizeData(dataList, decimalCount) {
        console.debug('ReportYtdResultsView#formatAndSummarizeData');

        return dataList.map((data) => {
            // Calculate percentages
            data.pct_ytd = data.target_ytd
                ? ((data.revenue_total / data.target_ytd) * 100).toFixed(1)
                : null;
            data.pct_year = data.target_year
                ? ((data.revenue_total / data.target_year) * 100).toFixed(1)
                : null;

            // Convert revenue to millions of yen
            data.revenue = data.revenue.map((amount) =>
                (amount / 1000000).toFixed(decimalCount),
            );
            data.revenue_total = (data.revenue_total / 1000000).toFixed(
                decimalCount,
            );

            // Convert targets to millions of yen
            data.target_ytd = (data.target_ytd / 1000000).toFixed(decimalCount);
            data.target_year = (data.target_year / 1000000).toFixed(
                decimalCount,
            );

            return data;
        });
    }

    prepareChartData(chart_data, dataList) {
        console.debug('ReportYtdResultsView#prepareChartData');

        const colors = config.brand_colors.hsv(
            dataList.length >= 5 ? dataList.length : 5,
            'short',
        );

        // Prepare chart data
        dataList.forEach((data, index) => {
            const color = Object.create(colors[index]);

            // Add dataset
            chart_data.datasets.push({
                label: data.name,
                data: data.revenue,
                borderColor: color.toRgbString(),
                backgroundColor: color.toRgbString(),
                hoverBackgroundColor: color.brighten().toRgbString(),
            });
        });
    }
}
