import { history, View } from 'backbone';
import ActivityCollection from '@/js/app/activity/collections/activities';
import ChangelogsCollection from '@/js/app/changelog/collections/changelogs';
import ChangelogsView from '@/js/app/changelog/views/changelogs';
import DealCollection from '@/js/app/deal/collections/deals';
import InquiryCollection from '@/js/app/inquiry/collections/inquiries';
import PropertyAlertCollection from '@/js/app/property-alert/collections/property-alerts';
import PropertyListCollection from '@/js/app/property-list/collections/property-lists';
import Session from '@/js/app/session';
import PeopleModel from '../models/people';
import template from '../templates/edit.html';
import PeopleEditActivityView from './edit-activity';
import PeopleEditAlertsView from './edit-alerts';
import PeopleEditCriteria from './edit-criteria';
import PeopleEditDealsView from './edit-deals';
import PeopleEditInquiriesView from './edit-inquiries';
import PeopleEditProfileView from './edit-profile';
import PeopleEditPropertyListsView from './edit-property-lists';
import { getCompanyName } from '@/js/app/formatter';

export default class PeopleEditView extends View {
    preinitialize(options) {
        this.tagName = 'section';
        this.id = 'people_edit';

        this.events = {
            'click button[data-action="delete"]': this.delete,
            'click button[data-action="changelog"]': this.changelog,
            'click [href="#tab-activity"]': this.showTabActivity,
            // 'click #tab-account': this.showTabAccount,
            'click [href="#tab-alerts"]': this.showTabAlerts,
            'click [href="#tab-inquiries"]': this.showTabInquiries,
            'click [href="#tab-property_lists"]': this.showTabPropertyLists,
            'click [href="#tab-deals"]': this.showTabDeals,
        };

        this.model = new PeopleModel({
            id: options.personId,
        });

        this.activityCollection = new ActivityCollection();
        this.propertyAlertCollection = new PropertyAlertCollection();
        this.inquiryCollection = new InquiryCollection();
        this.propertyListCollection = new PropertyListCollection();
        this.dealCollection = new DealCollection();

        // Create subviews with collections
        this.subviews = {
            profile: new PeopleEditProfileView({
                model: this.model,
            }),
            activity: new PeopleEditActivityView({
                collection: this.activityCollection,
            }),
            criteria: new PeopleEditCriteria({
                model: this.model,
            }),
            alerts: new PeopleEditAlertsView({
                collection: this.propertyAlertCollection,
            }),
            inquiries: new PeopleEditInquiriesView({
                collection: this.inquiryCollection,
            }),
            propertyLists: new PeopleEditPropertyListsView({
                collection: this.propertyListCollection,
                person: this.model,
            }),
            deals: new PeopleEditDealsView({
                collection: this.dealCollection,
            }),
        };
    }

    initialize() {
        _.bindAll(this, 'render');

        this.render();

        this.listenTo(
            this.model,
            'change:last_name change:first_name',
            this.handleNameChange,
        );
        this.listenTo(this.model, 'change:company', this.handleCompanyChange);
        this.listenTo(
            this.model,
            'change:is_real_estate_vendor',
            this.toggleTelFields,
        );
        this.listenTo(
            this.model,
            'change:is_tenant change:is_buyer change:is_seller change:is_owner',
            this.toggleTabs,
        );
        this.listenTo(
            this.model,
            'change:deleted_at',
            this.handleChangeDeleteAt,
        );

        // Trigger fetch
        this.model.fetch({
            data: {
                include: ['company', 'newsletter', 'assigned_staff'],
            },
        });
    }

    render() {
        console.debug('PeopleEditView#render');

        // Scroll to top
        $(window).scrollTop(0);

        this.el.innerHTML = template({
            isDeleteAllowed: Session.isAllowed('phnx:people:d'),
            isPropertyReadAllowed: Session.isAllowed('phnx:properties:r'),
        });

        // Change ID on header
        // this.el.querySelector('[data-outlet="id"]').innerText = this.model.id;

        this.subviews.profile
            .setElement(this.$el.find('#tab-profile'))
            .render();
        this.subviews.activity
            .setElement(this.$el.find('#tab-activity'))
            .render();
        this.subviews.criteria
            .setElement(this.$el.find('#tab-criteria'))
            .render();
        this.subviews.alerts.setElement(this.$el.find('#tab-alerts')).render();
        this.subviews.inquiries
            .setElement(this.$el.find('#tab-inquiries'))
            .render();
        this.subviews.propertyLists
            .setElement(this.$el.find('#tab-property_lists'))
            .render();
        this.subviews.deals.setElement(this.$el.find('#tab-deals')).render();

        this.changeName(
            this.model.get('last_name'),
            this.model.get('first_name'),
        );

        // Toggle tel fields
        this.toggleTelFields(this.model);

        return this;
    }

    handleNameChange(model, value, options) {
        console.debug('PeopleEditView#handleNameChange');

        if (!options.initialLoad) {
            this.changeName(model.get('last_name'), model.get('first_name'));
        }
    }

    changeName(lastName, firstName) {
        console.debug('PeopleEditView#changeName');

        this.el.querySelector('[data-outlet="last_name"]').innerText = lastName;
        this.el.querySelector('[data-outlet="first_name"]').innerText =
            firstName;
    }

    handleCompanyChange(model, value, options) {
        console.debug('PeopleEditView#handleCompanyChange');

        if (!options.initialLoad) {
            this.changeCompanyName(getCompanyName(value));
        }
    }

    changeCompanyName(value) {
        console.debug('PeopleEditView#changeCompanyName');

        this.el.querySelector('[data-outlet="company_name"]').innerText = value;
    }

    toggleTelFields(model) {
        console.debug('PeopleEditView#toggleTelFields');

        if (model.get('is_real_estate_vendor')) {
            $('[data-is-real-estate-vendor]').show();
        } else {
            $('[data-is-real-estate-vendor]').hide();
        }
    }

    toggleTabs(model) {
        console.debug('PeopleEditView#toggleTabs');

        // Show/hide tenant or buyer related tabs
        if (model.get('is_tenant') || model.get('is_buyer')) {
            this.el
                .querySelector('[data-tab="criteria"]')
                .classList.remove('d-none');
            this.el
                .querySelector('[data-tab="property_lists"]')
                .classList.remove('d-none');
        } else {
            this.el
                .querySelector('[data-tab="criteria"]')
                .classList.add('d-none');
            this.el
                .querySelector('[data-tab="property_lists"]')
                .classList.add('d-none');
        }

        // Show/hide property tab
        // if (model.get('is_seller') || model.get('is_owner')) {
        //     this.el.querySelector('[data-tab="property"]').classList.remove('d-none');
        // } else {
        //     this.el.querySelector('[data-tab="property"]').classList.add('d-none');
        // }
    }

    delete() {
        console.debug('PeopleEditView#delete');

        if (
            confirm(
                'Are you sure you want to delete this person? This cannot be undone!',
            )
        ) {
            this.model.destroy({
                success() {
                    history.navigate('clients/search', { trigger: true });
                },
                error(model, response) {
                    alert(response.responseText);
                },
            });
        }
    }

    changelog() {
        console.debug('PeopleEditView#changelog');

        // Create modal view, with collection
        const changelogView = new ChangelogsView({
            collection: new ChangelogsCollection(),
        });

        // Fetch collection
        changelogView.collection.fetch({
            data: {
                resource_type: 'person',
                resource_id: this.model.get('id'),
            },
        });
    }

    handleChangeDeleteAt(model, value) {
        console.debug('PeopleEditView#handleChangeDeleteAt');

        const deletedAlert = this.el.querySelector(
            '[data-outlet="deleted-alert"]',
        );

        if (value === null) {
            deletedAlert.classList.add('d-none');
        } else {
            deletedAlert.classList.remove('d-none');
        }
    }

    showTabActivity() {
        console.debug('PeopleEditView#showTabActivity');

        // Fetch activity
        this.activityCollection.fetch({
            data: {
                addressbook_id: this.model.id,
                order: 'timestamp DESC',
                limit: 100,
                include: ['person'],
            },
            reset: true,
        });
    }

    showTabAlerts() {
        console.debug('PeopleEditView#showTabAlerts');

        // Fetch alerts
        this.subviews.alerts.collection.fetch({
            data: {
                email_address: this.model.get('email'),
                include: ['search_text'],
            },
            reset: true,
        });
    }

    showTabInquiries($e) {
        console.debug('PeopleEditView#showTabInquiries');

        $e.preventDefault();

        // Display inquiries tab
        this.$el.find('#tab-content').html(this.subviews.inquiries.el);

        // Fetch inquiries
        this.inquiryCollection.fetch({
            data: {
                addressbook_id: this.model.id,
                include: ['property'],
            },
            reset: true,
        });
    }

    showTabPropertyLists() {
        console.debug('PeopleEditView#showTabPropertyLists');

        this.propertyListCollection.fetch({
            data: {
                include: ['created_by'],
                addressbook_id: this.model.id,
            },
            reset: true,
        });
    }

    showTabDeals() {
        console.debug('PeopleEditView#showTabDeals');

        this.dealCollection.fetch({
            data: {
                addressbook_id: this.model.id,
            },
        });
    }
}
