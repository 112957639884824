import { Collection } from 'backbone';
import config from '../../config';
import PropertyAlertEmailModel from '../../property-alert-email/models/property-alert-email';

class PropertyAlertEmailsCollection extends Collection {
    preinitialize(models, options) {
        this.alertId = options.alertId;
        this.model = PropertyAlertEmailModel;
    }

    url() {
        return config.api.url + 'property_alert/' + this.alertId + '/emails';
    }

    parse(response) {
        this.total_rows = response.total_rows;

        return response.results;
    }
}

export default PropertyAlertEmailsCollection;
