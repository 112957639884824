import * as Formatter from '@/js/app/formatter';
import Session from '@/js/app/session';
import * as TextHelper from '@/js/app/text-helper';
import { View } from 'backbone';
import template from '../templates/assignment_search_results.html';

export default class ClientAssignmentResultsView extends View {
    preinitialize() {
        this.events = {
            'click th[data-col]': this.handleClickColumn,
        };

        this.criteria = {};
    }

    initialize() {
        // When collection updates/resets; render
        this.listenTo(this.model, 'change', this.render);
    }

    render() {
        console.debug('ClientAssignmentResultsView#render');

        this.el.innerHTML = template({
            people: this.model.toJSON(),
            TextHelper,
            Formatter,
            Session,
        });

        return this;
    }

    handleClickColumn(event) {
        console.debug(event);

        // Check current sort key
        if (this.criteria.sort_key === event.currentTarget.dataset.col) {
            // If same as current sort key, swap sort order
            this.criteria.sort_order =
                this.criteria.sort_order === 'asc' ? 'desc' : 'asc';
        } else {
            // Change sort key and reset sort order
            this.criteria.sort_key = event.currentTarget.dataset.col;
            this.criteria.sort_order = 'asc';
        }

        // Sort collection
        this.model.toJSON().sort((a, b) => {
            if (this.criteria.sort_order === 'asc') {
                return a[this.criteria.sort_key] > b[this.criteria.sort_key]
                    ? 1
                    : -1;
            } else {
                return a[this.criteria.sort_key] < b[this.criteria.sort_key]
                    ? 1
                    : -1;
            }
        });

        // Re-render
        this.render();
    }
}
