module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 class="h3">Results</h2>\n';
 if ('undefined' !== typeof people && people.length > 0) { ;
__p += '\n\n<table class="table table-sm table-striped">\n  <caption class="text-right">' +
((__t = ( people.length )) == null ? '' : __t) +
' result(s)</caption>\n  <thead>\n    <tr>\n      <th>ID</th>\n      <th>Name</th>\n      <th>Division</th>\n      <th>Type</th>\n      <th class="clickable" data-col="status">Status <i class="fa fa-sort ml-1"></i></th>\n      <th class="clickable" data-col="assigned_date">Assigned Date <i class="fa fa-sort ml-1"></i></th>\n    </tr>\n  </thead>\n  <tbody>\n    ';
 people.forEach((p) => { ;
__p += '\n    <tr data-id="' +
((__t = ( p.id )) == null ? '' : __t) +
'">\n      <td><a href="#people/edit/' +
((__t = ( p.id )) == null ? '' : __t) +
'">' +
((__t = ( p.id )) == null ? '' : __t) +
'</a></td>\n      <td><a href="#people/edit/' +
((__t = ( p.id )) == null ? '' : __t) +
'">' +
((__t = ( Formatter.getName(p) )) == null ? '' : __t) +
'</a></td>\n      <td>' +
((__t = ( p.division )) == null ? '' : __t) +
'</td>\n      <td>\n        ';
 if (p.types.length > 0) { ;
__p += '\n        ';
 p.types.forEach((type, i) => { ;
__p += '\n        ' +
((__t = ( type.capitalize() )) == null ? '' : __t) +
'' +
((__t = ( i !== p.types.length - 1 ? ',': '' )) == null ? '' : __t) +
'\n        ';
 }); ;
__p += '\n        ';
 } else { ;
__p += '\n        <span class="badge badge-info">0 Type(s)</span>\n        ';
 }; ;
__p += '\n      </td>\n      <td>\n        ';
 if (p.status === 'active') { ;
__p += '\n        <span class="badge badge-success">Active</span>\n        ';
 } else if (p.status === 'pending') { ;
__p += '\n        <span class="badge badge-warning">Pending</span>\n        ';
 } else if (p.status === 'inactive') { ;
__p += '\n        <span class="badge badge-danger">Inactive</span>\n        ';
 } else if (p.status === 'unassigned') { ;
__p += '\n        <span class="badge badge-secondary">Unassigned</span>\n        ';
 } ;
__p += '\n      </td>\n      <td>\n        <span class="mr-2">' +
((__t = ( Formatter.format_date(p.assigned_date, 'YYYY/MM/DD') )) == null ? '' : __t) +
'</span>\n        ' +
((__t = ( Formatter.days_from_today(p.assigned_date) )) == null ? '' : __t) +
'\n      </td>\n    </tr>\n    ';
 }); ;
__p += '\n  </tbody>\n</table>\n\n';
 } else { ;
__p += '\n<p>No people match your search query.</p>\n';
 } ;


}
return __p
};
