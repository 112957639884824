import { Events } from 'backbone';
import config from '@/js/app/config';
import OAuth2Client from '@/js/app/oauth2-client';

export default class ClientAssignedModel {
    constructor() {
        _.extend(this, Events);
        this.data = [];
    }

    fetch(staffId) {
        this.trigger('request');

        OAuth2Client.ajax({
            url: config.api.url + 'reports/client_assignment',
            dataType: 'json',
            data: {
                staff_id: staffId,
            },
        })
            .then((response) => {
                this.trigger('request_done');
                this.data = response;

                this.trigger('change');
            })
            .catch((error) => {
                console.error(error);
            });
    }

    toJSON() {
        return this.data;
    }
}
