import ModalView from '../../generic/modal';
import template from '../templates/email-events.html';

export default class EmailEventsModalView extends ModalView {
    render() {
        console.debug('EmailEventsModalView#render');

        this.renderModal({ size: 'xl' }).modalContent.innerHTML = template({
            events: this.events,
            total_rows: this.total_rows,
            limit: this.limit,
            offset: this.offset,
        });

        return this;
    }
}
