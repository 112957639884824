import { View } from 'backbone';
import PropertyAlertCollection from '../../property-alert/collections/property-alerts';
import PropertyAlertCriteria from '../../property-alert/criteria/property-alert';
import PropertyAlertCriteriaModalView from '../../property-alert/views/alert-criteria';
import PropertyAlertEmailsCollection from '../collections/property-alert-emails';
import template from '../templates/index.html';
import { redactEmail } from '../../formatter';

class PropertyAlertEmailsIndexView extends View {
    preinitialize() {
        this.events = {
            'click ul.pagination a': this.changePage,
            'click [data-action="criteria"]': this.handleCriteriaClick,
            'change input[name="status"]': this.handleStatusFilter,
            'click #email-clear': this.handleEmailClear,
            'click #email-search': this.handleEmailSearch,
            'keyup #field-email_search': this.handleEmailSearch,
            'change select[name="sort_key"]': this.handleSortBy,
            'change select[name="sort_order"]': this.handleSortOrder,
            'change input[name="has_clicks"]': this.handleHasClicks,
            'change input[name="has_opens"]': this.handleHasOpens,
        };

        this.tagName = 'section';
        this.id = 'propertyAlertEmails';

        this.propertyAlertCriteria = new PropertyAlertCriteria();
        this.propertyAlertCollection = new PropertyAlertCollection();

        this.propertyAlertEmailsCollection =
            new PropertyAlertEmailsCollection();

        this.subviews = {
            propertyCriteriaModal: new PropertyAlertCriteriaModalView(),
        };
    }

    initialize() {
        this.render();

        // Initiate fetch on property alert emails collection
        this.propertyAlertCollection.fetch({
            data: this.propertyAlertCriteria.toJSON(),
        });

        // When criteria changes; search
        this.listenTo(this.propertyAlertCriteria, 'change', this.search);

        // When collection updates/resets; render
        this.listenTo(this.propertyAlertCollection, 'sync', this.render);
    }

    render() {
        console.debug('PropertyAlertEmailsIndexView#render');

        // Render template
        this.el.innerHTML = template({
            property_alerts: this.propertyAlertCollection.toJSON(),
            criteria: this.propertyAlertCriteria.toJSON(),
            total_rows: this.propertyAlertCollection.total_rows,
            limit: this.propertyAlertCriteria.get('limit'),
            offset: this.propertyAlertCriteria.get('offset'),
            redactEmail,
        });

        this.el.querySelectorAll('input[name="status"]').forEach((input) => {
            if (input.value === this.propertyAlertCriteria.get('status')) {
                input.checked = true;
            }
        });

        if (this.propertyAlertCriteria.get('email_address_partial')) {
            this.el.querySelector('input[name="email_search"]').value =
                this.propertyAlertCriteria.get('email_address_partial');
        }

        if (this.propertyAlertCriteria.get('sort_key')) {
            this.el.querySelector('select[name="sort_key"]').value =
                this.propertyAlertCriteria.get('sort_key');
        }

        if (this.propertyAlertCriteria.get('sort_order')) {
            this.el.querySelector('select[name="sort_order"]').value =
                this.propertyAlertCriteria.get('sort_order');
        }

        if (this.propertyAlertCriteria.get('has_clicks')) {
            this.el.querySelector('input[name="has_clicks"]').checked =
                this.propertyAlertCriteria.get('has_clicks');
        }

        if (this.propertyAlertCriteria.get('has_opens')) {
            this.el.querySelector('input[name="has_opens"]').checked =
                this.propertyAlertCriteria.get('has_opens');
        }

        return this;
    }

    changePage($e) {
        console.debug('PropertyAlertEmailsIndexView#changePage');

        $e.preventDefault();

        // Set criteria offset from clicked UI element
        this.propertyAlertCriteria.set(
            'offset',
            Number($e.target.dataset.offset),
        );
    }

    search() {
        console.debug('PropertyAlertEmailsIndexView#search');

        // Fetch collection
        this.propertyAlertCollection.fetch({
            data: this.propertyAlertCriteria.toJSON(),
            reset: true,
        });
    }

    handleStatusFilter(e) {
        this.propertyAlertCriteria.set('status', e.target.value);
    }

    handleEmailSearch(e) {
        e.preventDefault();

        const enterKey = 13;

        if (
            (e.type === 'keyup' && e.which === enterKey) ||
            e.type === 'click'
        ) {
            const emailInputValue = this.el.querySelector(
                'input[name="email_search"',
            ).value;

            this.propertyAlertCriteria.set(
                'email_address_partial',
                emailInputValue,
            );
        }
    }

    handleEmailClear() {
        this.propertyAlertCriteria.set('email_address_partial', '');
    }

    handleSortBy(e) {
        const sortBy = e.target.value;
        this.propertyAlertCriteria.set('sort_key', sortBy);
    }

    handleSortOrder(e) {
        const sortOrder = e.target.value;
        this.propertyAlertCriteria.set('sort_order', sortOrder);
    }

    handleHasClicks(e) {
        console.log(e.target.type);
        const hasClicks = e.target.checked;

        hasClicks
            ? this.propertyAlertCriteria.set('has_clicks', hasClicks)
            : this.propertyAlertCriteria.unset('has_clicks');
    }

    handleHasOpens(e) {
        const hasOpens = e.target.checked;

        hasOpens
            ? this.propertyAlertCriteria.set('has_opens', hasOpens)
            : this.propertyAlertCriteria.unset('has_opens');
    }

    handleCriteriaClick(e) {
        console.debug('PropertyAlertEmailsIndexView#handleCriteriaClick');

        e.stopPropagation();

        const selectedAlert = this.propertyAlertCollection.findWhere({
            id: Number(e.currentTarget.dataset.alertId),
        });

        this.subviews.propertyCriteriaModal.search_text =
            selectedAlert.get('search_text');
        this.subviews.propertyCriteriaModal.render();
    }
}

export default PropertyAlertEmailsIndexView;
