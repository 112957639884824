import Criteria from '../../criteria-model';

export default Criteria.extend({
    defaults: function () {
        return {
            limit: 20,
            offset: 0,
            include: ['search_text', 'email_count'],
            status: '',
            sort_key: 'created_at',
            sort_order: 'desc',
        };
    },
});
