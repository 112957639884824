module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="mt-3">\n  <div class="row align-items-end">\n      <div class="col">\n          <h1 class="h2 my-0">Alert Emails #' +
((__t = ( alertId )) == null ? '' : __t) +
'</h1>\n      </div>\n  </div>\n</div>\n\n<hr>\n\n';
 if ( alert_emails.length > 0) { ;
__p += '\n  <table class="table table-sm table-striped">\n    <thead>\n        <tr>\n            <th>Timestamp</th>\n            <th>Opens</th>\n            <th>Clicks</th>\n            <th>Details</th>\n        </tr>\n    </thead>\n    <tbody>\n      ';
 alert_emails.forEach((email) => { ;
__p += '\n        <tr>\n          <td>' +
((__t = ( email.created_at )) == null ? '' : __t) +
'</td>\n          <td>' +
((__t = ( email.events.open )) == null ? '' : __t) +
'</td>\n          <td>' +
((__t = ( email.events.click )) == null ? '' : __t) +
'</td>\n          ';
 if (Number(email.events.open) !== 0 || Number(email.events.click) !== 0) { ;
__p += '\n            <td><a class="link-event" data-action="events" data-email-id="' +
((__t = ( email.id )) == null ? '' : __t) +
'">See Events</a></td>\n          ';
 } else { ;
__p += '\n            <td class="tt-disabled">No Events</td>\n          ';
 } ;
__p += '\n      </tr>\n      ';
 }) ;
__p += '\n\n    </tbody>\n</table>\n\n' + require("../../generic/templates/pagination.html").apply(null,arguments) + '\n';
 } else { ;
__p += '\n<p>No alerts match your search query.</p>\n';
 } ;


}
return __p
};
