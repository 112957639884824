module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal-header">\n  <h5 class="modal-title">Alert Criteria</h5>\n\n  <button type="button" class="close" data-dismiss="modal">\n      <span aria-hidden="true">&times;</span>\n  </button>\n</div>\n\n<div class="modal-body">\n  ';
 search_text.forEach((criteria) => { ;
__p += '\n    <p>' +
((__t = ( criteria )) == null ? '' : __t) +
'</p>\n  ';
 }) ;
__p += '\n</div>';

}
return __p
};
