import { Collection, View } from 'backbone';
import PropertyAlertEmailsCollection from '../../property-alert/collections/property-alert-emails';
import Spinner from '../../ui/views/spinner';
import PropertyAlertEmailEventsCollection from '../collections/email-events';
import PropertyAlertEmailCriteria from '../criteria/property-alert-email';
import template from '../templates/email-list.html';
import EmailEventsModalView from './email-events';

class PropertyAlertEmailListView extends View {
    preinitialize(options) {
        this.events = {
            'click ul.pagination a': this.changePage,
            'click [data-action="events"]': this.handleEventsClick,
        };

        this.propertyAlertEmailCriteria = new PropertyAlertEmailCriteria();

        this.collection = new PropertyAlertEmailsCollection([], {
            alertId: options.alertId,
        });

        this.propertyAlertEmailEventsCollection =
            new PropertyAlertEmailEventsCollection([], {
                id: null,
            });

        this.alertId = options.alertId;

        this.subviews = {
            propertyCriteriaModal: new EmailEventsModalView(),
        };
    }

    initialize() {
        this.render();

        this.spinner = new Spinner({
            top: true,
        });

        // When collection starts request; start spinner
        this.listenTo(this.collection, 'request', function (collection) {
            if (collection instanceof Collection) {
                this.spinner.spin(this.el);
            }
        });

        // When collection finishes request; stop spinner
        this.listenTo(this.collection, 'sync error', function (collection) {
            if (collection instanceof Collection) {
                this.spinner.stop();
            }
        });

        // Trigger fetch
        this.collection.fetch({
            data: this.propertyAlertEmailCriteria.toJSON(),
        });

        this.listenTo(this.collection, 'sync', this.render);

        // When criteria changes; search
        this.listenTo(this.propertyAlertEmailCriteria, 'change', this.search);

        // When collection updates/resets; render
        this.listenTo(this.propertyAlertCollection, 'sync', this.render);
    }

    render() {
        console.debug('PropertyAlertEmailListView#render');

        // Render template
        this.el.innerHTML = template({
            alertId: this.alertId,
            alert_emails: this.collection.toJSON(),
            total_rows: this.collection.total_rows,
            limit: this.propertyAlertEmailCriteria.get('limit'),
            offset: this.propertyAlertEmailCriteria.get('offset'),
        });

        return this;
    }

    changePage($e) {
        console.debug('PropertyAlertEmailListView#changePage');

        $e.preventDefault();

        // Set criteria offset from clicked UI element
        this.propertyAlertEmailCriteria.set(
            'offset',
            Number($e.target.dataset.offset),
        );
    }

    search() {
        console.debug('PropertyAlertEmailsIndexView#search');

        // Fetch collection
        this.collection.fetch({
            data: this.propertyAlertEmailCriteria.toJSON(),
            reset: true,
        });
    }

    async handleEventsClick(e) {
        console.debug('PropertyAlertEmailListView#handleEventsClick');

        e.stopPropagation();

        const emailId = Number(e.currentTarget.dataset.emailId);

        this.propertyAlertEmailEventsCollection.id = emailId;

        await this.propertyAlertEmailEventsCollection.fetch({
            data: {
                include: ['parsed_user_agent'],
            },
        });

        this.subviews.propertyCriteriaModal.events =
            this.propertyAlertEmailEventsCollection.toJSON();
        this.subviews.propertyCriteriaModal.total_rows =
            this.propertyAlertEmailEventsCollection.total_rows;
        this.subviews.propertyCriteriaModal.limit =
            this.propertyAlertEmailCriteria.get('limit');
        this.subviews.propertyCriteriaModal.offset =
            this.propertyAlertEmailCriteria.get('offset');

        this.subviews.propertyCriteriaModal.render();
    }
}

export default PropertyAlertEmailListView;
