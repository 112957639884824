import { Collection } from 'backbone';
import config from '../../config';
import PropertyAlertEmailModel from '../models/property-alert-email';

export default Collection.extend({
    url: config.api.url + 'property_alert_emails',

    model: PropertyAlertEmailModel,

    parse: function (response) {
        this.total_rows = response.total_rows;

        return response.results;
    },
});
