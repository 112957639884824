import DivisionsCollection from '@/js/app/division/collections/divisions';
import GroupCompaniesCollection from '@/js/app/group-company/collection';
import UiDynamicSelectView from '@/js/app/ui/views/dynamic-select';
import populateForm from '@/js/libs/populate-form';
import { View } from 'backbone';
import numeral from 'numeral';
import template from '../templates/edit_profile.html';

export default class StaffEditProfileView extends View {
    preinitialize() {
        this.tagName = 'form';

        this.events = {
            'change #field-company': this.handleChangeCompany,
            'change input,select,textarea': this.handleInputChange,
        };

        this.divisionsCollection = new DivisionsCollection();
        this.groupCompaniesCollection = new GroupCompaniesCollection();

        // Create subviews
        this.subviews = {
            groupCompanySelect: new UiDynamicSelectView({
                id: 'field-company',
                collection: this.groupCompaniesCollection,
                mapper: function (model) {
                    return {
                        label: model.get('name'),
                        value: model.id,
                    };
                },
                attributes: {
                    name: 'company_id',
                },
                triggerChange: false,
                prependOptions: [{ label: '', value: '' }],
            }),
            divisionSelect: new UiDynamicSelectView({
                id: 'field-division',
                collection: this.divisionsCollection,
                mapper: function (model) {
                    return {
                        label: model.get('name'),
                        value: model.id,
                    };
                },
                attributes: {
                    name: 'division_id',
                },
                triggerChange: false,
                prependOptions: [{ label: '', value: '' }],
            }),
        };
    }

    initialize() {
        this.listenTo(this.model, 'change', this.handleModelChange);
        this.listenTo(this.model, 'change:email', this.handleEmailChange);
        this.listenTo(
            this.model,
            'change:company_id',
            this.handleCompanyIdChange,
        );
        this.listenTo(
            this.model,
            'change:division_id',
            this.handleDivisionIdChange,
        );

        // Fetch group companies
        this.groupCompaniesCollection.fetch({
            data: {
                order: 'order',
            },
        });
    }

    render() {
        console.debug('StaffEditProfileView#render');

        this.el.innerHTML = template();

        this.$el
            .find('#cntr-company')
            .html(this.subviews.groupCompanySelect.el);
        this.$el.find('#cntr-division').html(this.subviews.divisionSelect.el);

        return this;
    }

    fetchDivisions(companyId) {
        // Fetch divisions
        this.divisionsCollection.fetch({
            data: {
                company_id: companyId,
                order: 'order',
            },
        });
    }

    handleChangeCompany(e) {
        console.debug('StaffEditProfileView#handleChangeCompany');

        const companyId = e.currentTarget.value;

        this.fetchDivisions(companyId);
    }

    handleInputChange($e) {
        console.debug('StaffEditProfileView#handleInputChange');

        const t = $e.currentTarget;

        // If no name, return
        if (t.name === '') {
            return;
        }

        let value;
        // If type is checkbox; get property "checked"
        if (t.type === 'checkbox') {
            value = t.checked;
        }
        // Else if number type, or data attribute "numeral" exists; unformat number
        else if (t.type === 'number' || t.dataset.numeral) {
            value = numeral(t.value).value() || null;
        }
        // Else; use value
        else {
            if (t.value === '') {
                value = null;
            } else {
                value = t.value;
            }
        }

        // Save new value
        this.model.save(t.name, value, { patch: true, wait: true });
    }

    handleModelChange(model) {
        console.debug('StaffEditProfileView#handleModelChange');

        populateForm(this.el, model.changedAttributes());
    }

    handleCompanyIdChange(model, value) {
        console.debug('StaffEditProfileView#handleCompanyIdChange');

        this.subviews.groupCompanySelect.setSelected(value, true); // Prevent triggering "change" event, which will cause model to be saved

        this.fetchDivisions(value); // Fetch divisions manually, because "change" event had to be suppressed
    }

    handleDivisionIdChange(model, value) {
        console.debug('StaffEditProfileView#handleDivisionIdChange');

        this.subviews.divisionSelect.setSelected(value);
    }

    handleEmailChange(model, value) {
        console.debug('StaffEditProfileView#handleEmailChange');

        this.updateEmailLink(value);
    }

    updateEmailLink(value) {
        console.debug('StaffEditProfileView#updateEmailLink');

        const el = this.$el.find('#link-email');
        el.prop('href', 'mailto:' + value);
        if (_.isEmpty(value)) {
            el.hide();
        } else {
            el.show();
        }
    }
}
