module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="modal-header">\n    <h5 class="modal-title">Email Events</h5>\n\n    <button type="button" class="close" data-dismiss="modal">\n        <span aria-hidden="true">&times;</span>\n    </button>\n</div>\n\n<div class="modal-body">\n    ';
 if ( events.length > 0) { ;
__p += '\n    <table class="table table-sm table-striped">\n        <thead>\n            <tr>\n                <th>Timestamp</th>\n                <th>Event</th>\n                <th>Country</th>\n                <th>Browser/Platform</th>\n            </tr>\n        </thead>\n        <tbody>\n            ';
 events.forEach((event) => { ;
__p += '\n            <tr>\n                <td>' +
((__t = ( event.timestamp )) == null ? '' : __t) +
'</td>\n                <td>' +
((__t = ( event.event )) == null ? '' : __t) +
'</td>\n\n                <td><img\n                    src="https://flagcdn.com/16x12/' +
((__t = ( event.country_code.toLowerCase() )) == null ? '' : __t) +
'.png"\n                    srcset="https://flagcdn.com/32x24/' +
((__t = ( event.country_code.toLowerCase() )) == null ? '' : __t) +
'.png 2x,\n                      https://flagcdn.com/48x36/' +
((__t = ( event.country_code.toLowerCase() )) == null ? '' : __t) +
'.png 3x"\n                    width="16"\n                    height="12"\n                    alt="' +
((__t = ( event.country_code )) == null ? '' : __t) +
'"></td>\n                <td>' +
((__t = ( event.parsed_user_agent.browser )) == null ? '' : __t) +
' (' +
((__t = ( event.parsed_user_agent.platform )) == null ? '' : __t) +
')</td>\n            </tr>\n            ';
 }) ;
__p += '\n        </tbody>\n    </table>\n\n    ' + require("../../generic/templates/pagination.html").apply(null,arguments) + ' ';
 } else { ;
__p += '\n    <p>No alerts match your search query.</p>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};
