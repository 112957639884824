import DealFileCollection from '@/js/app/deal/collections/files';
import ModalFilesView from '@/js/app/deal/views/modal-files';
import * as Formatter from '@/js/app/formatter';
import * as TextHelper from '@/js/app/text-helper';
import { View } from 'backbone';
import template from '../templates/result_row.html';

class ReportDaichoResultRowView extends View {
    preinitialize(options) {
        this.tagName = 'tr';
        this.className = 'daicho-row';

        this.events = {
            'click button[data-action="files"]': this.handleFilesClick,
        };

        this.data = options.data;
        this.type = options.type;

        this.subviews = {
            filesModal: new ModalFilesView({
                title: 'Files',
                collection: new DealFileCollection(null, {
                    deal_id: this.data.id,
                }),
            }),
        };
    }

    render() {
        console.debug('ReportDaichoResultRowView#render');

        if (this.data.agent) {
            console.log(this.data.agent);
        }

        this.el.innerHTML = template({
            result: this.data,
            type: this.type,
            Formatter,
            TextHelper,
        });

        return this;
    }

    handleFilesClick() {
        console.debug('ReportDaichoResultRowView#handleFilesClick');

        this.subviews.filesModal.render();

        // Trigger fetch on collection
        this.subviews.filesModal.collection.fetch({
            data: {
                order: 'last_modified_at',
                include: ['last_modified_by'],
            },
        });
    }
}

export default ReportDaichoResultRowView;
