import moment from 'moment';
import numeral from 'numeral';
import config from './config';
import Preferences from './preferences';
import * as TextHelper from './text-helper';

const __ = {
    wp_rentals_path: 'rent',
    wp_sales_path: 'buy',
    wp_short_term_path: 'short-term',
    wp_investment_path: 'investment-properties',
};

export function determineType(p) {
    if (p.price > 0) {
        return 'sales';
    } else if (p.rent > 0) {
        return 'rent';
    } else if (p.monthly_rate > 0) {
        return 'monthly';
    } else if (p.weekly_rate > 0) {
        return 'weekly';
    } else {
        return '';
    }
}

function determineSectionPath(property_type, investment = 0) {
    if (investment) {
        return __.wp_investment_path;
    } else {
        switch (property_type) {
            case 'sales':
                return __.wp_sales_path;
            case 'rent':
                return __.wp_rentals_path;
            case 'monthly':
            case 'weekly':
                return __.wp_short_term_path;
            default:
                return '';
        }
    }
}

export function public_search_url(
    language,
    property_type,
    investment,
    searchid,
) {
    return (
        config.site[language] +
        determineSectionPath(property_type, investment) +
        '/search/' +
        searchid +
        '/'
    );
}

export function deal_edit_url(deal) {
    return '#deals/edit/' + deal.id;
}

export function property_edit_url(property) {
    let url = '#property/edit/' + property.building_id + '.0';

    if (
        property.structure_type === 'unit' ||
        property.structure_type === 'house'
    ) {
        url += '/' + property.id;
    }

    return url;
}

export function building_edit_url(property) {
    const building = property.structure ? property.structure : property;

    let url = '#property/edit/' + building.id + '.0';

    if (property.structure_type === 'house') {
        url += '/' + property.id;
    }

    return url;
}

export function zumen_url(type, properties, language = 'en') {
    if (_.isArray(properties)) {
        // If properties is empty, return
        if (properties.length === 0) {
            return;
        }

        // If type is "building", prefix IDs with "b"
        if (type === 'building') {
            properties = properties.map((id) => 'b' + id);
        }

        // Build and return URL
        return (
            config.site[language] +
            'list/properties/' +
            properties.join('+') +
            '/print/'
        );
    } else if (_.isNumber(properties)) {
        // If property is 0, return
        if (Number(properties) === 0) {
            return;
        }

        // If type is "building", prefix ID with "b"
        if (type === 'building') {
            properties = 'b' + properties;
        }

        // Build and return URL
        return (
            config.site[language] + 'list/properties/' + properties + '/print/'
        );
    } else {
        throw Error('Argument "properties" must be an array or number');
    }
}

export function building_seo_url(location, property, section, language = 'en') {
    let baseUrl = config.site[language] + determineSectionPath(section);

    // Add location slugs for Japanese site
    if (language === 'ja') {
        baseUrl +=
            '/' +
            location.prefecture_slug +
            '/' +
            location.city_slug +
            '/' +
            location.neighborhood_slug;
    }

    return (
        baseUrl + '/' + property.structure_name_slug + '/b' + property.id + '/'
    ); // Prefix with "b"
}

export function unit_seo_url(
    location,
    structure,
    property,
    section,
    language = 'en',
) {
    let baseUrl = config.site[language] + determineSectionPath(section);

    // Add location slugs for Japanese site
    if (language === 'ja') {
        baseUrl +=
            '/' +
            location.prefecture_slug +
            '/' +
            location.city_slug +
            '/' +
            location.neighborhood_slug;
    }

    return (
        baseUrl + '/' + structure.structure_name_slug + '/' + property.id + '/'
    );
}

export function building_page_url(
    location,
    property,
    section,
    language = 'en',
) {
    if (language === 'en') {
        return (
            config.site[language] +
            determineSectionPath(section) +
            '/' +
            property.structure_name_slug +
            '/'
        );
    } else if (language === 'ja') {
        return (
            config.site[language] +
            determineSectionPath(section) +
            '/' +
            location.prefecture_slug +
            '/' +
            location.city_slug +
            '/' +
            location.neighborhood_slug +
            '/' +
            property.structure_name_slug +
            '/'
        );
    }
}

export function building_print_url(
    location,
    property,
    section,
    language = 'en',
) {
    return building_seo_url(location, property, section, language) + '/print/';
}

export function unit_print_url(
    location,
    structure,
    property,
    section,
    language = 'en',
) {
    return (
        unit_seo_url(location, structure, property, section, language) +
        'print/'
    );
}

export function list_url(l) {
    return config.site.en + 'lists/' + l.slug + '/' + l.id + '/';
}

export function rms_url(id) {
    return config.rms_url + '#/revenue/' + id;
}

export function timestamp(ts, br = false, format = false) {
    let d;

    if (
        'undefined' !== typeof ts &&
        ts &&
        null != ts &&
        'object' == typeof ts &&
        'function' == typeof ts.getDate
    ) {
        d = ts;
    } else if ('undefined' !== typeof ts && ts && null != ts) {
        d = ts + '+0000';
        if (false === moment.utc(d).isValid()) {
            return '';
        }
    } else {
        d = new Date();
    }

    if ('short' == format) {
        return moment.utc(d).local().format('MM-DD');
    } else if ('date' == format) {
        return moment.utc(d).local().format('YYYY-MM-DD');
    }
    // else {
    // 	return d.getFullYear() + '-' + mo + '-' + da + ( -1 != br ? ((br ? '<br>' : ' ') + h + ':' + mi + ':' + s) : '');
    // }
    else {
        return moment
            .utc(d)
            .local()
            .format(
                'YYYY-MM-DD' +
                    (-1 != br ? (br ? '<br>' : ' ') + 'HH:mm:ss' : ''),
            );
    }
}

export function datestamp(ts, format = false) {
    if (ts) {
        const d = new Date();
        d.setTime(Date.parse(ts + ' UTC+0'));

        if ('human' === format && d.getTime() < Date.now()) {
            return 'Now';
        } else {
            return moment(ts).format('Y-M-D');
        }
    } else {
        return '';
    }
}

export function format_date(date, format = 'Do MMM YYYY') {
    return moment(date).format(format);
}

export function days_from_today(date) {
    const today = moment();
    const dateToCompare = moment(date);

    let result = dateToCompare.diff(today, 'days');

    let badgeClass;
    let badgeLabel;

    if (result < 0) {
        result = result * -1;
        badgeLabel = `${result} days ago`;

        if (result < 180) {
            badgeClass = 'success';
        } else if (result < 365) {
            badgeClass = 'warning';
        } else if (result < 730) {
            badgeClass = 'danger';
        } else {
            badgeClass = 'dark';
        }
    } else if (result >= 0) {
        badgeLabel = `In ${result} days`;
        badgeClass = 'success';
    } else {
        badgeLabel = `No date found`;
        badgeClass = 'info';
    }

    return `
    <span class="badge badge-${badgeClass}">${badgeLabel}</span>
    `;
}

export function mimeTypeToIcon(mimeType) {
    switch (mimeType) {
        // Text
        case 'text/plain':
            return 'txt';

        // CSV
        case 'text/csv':
            return 'csv';

        // PDF
        case 'application/pdf':
            return 'pdf';

        // Word
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'doc';

        // Excel
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'xls';

        // Powerpoint
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return 'ppt';

        // PNG
        case 'image/png':
            return 'png';

        // JPG
        case 'image/jpeg':
            return 'jpg';

        // MP3
        case 'audio/mpeg3':
        case 'audio/x-mpeg-3':
        case 'audio/mpeg':
        case 'audio/x-mpeg':
            return 'mp3';

        // MPEG4
        case 'video/mp4':
            return 'mp4';

        // AVI
        case 'video/avi':
        case 'video/msvideo':
        case 'video/x-msvideo':
            return 'avi';

        // Default
        default:
            return 'file';
    }
}

export function filesize(size) {
    const units = ['B', 'KB', 'MB', 'GB'];
    let i = 0;

    while (size > 1024) {
        size /= 1024;
        i++;
    }

    return (Number.isInteger(size) ? size : size.toFixed(1)) + ' ' + units[i];
}

export function bedrooms(p, short = false) {
    if (p.bedroom_no == null) {
        return '';
    }

    let other_rooms = '';
    other_rooms +=
        'undefined' !== typeof p.bedroom_study && 1 == p.bedroom_study
            ? '+S'
            : '';
    other_rooms +=
        'undefined' !== typeof p.bedroom_family && 1 == p.bedroom_family
            ? '+F'
            : '';
    other_rooms +=
        'undefined' !== typeof p.bedroom_den && 1 == p.bedroom_den ? '+D' : '';
    other_rooms +=
        'undefined' !== typeof p.bedroom_maid && 1 == p.bedroom_maid
            ? '+M'
            : '';
    other_rooms +=
        'undefined' !== typeof p.bedroom_playroom && 1 == p.bedroom_playroom
            ? '+P'
            : '';
    other_rooms +=
        'undefined' !== typeof p.bedroom_tatami && 1 == p.bedroom_tatami
            ? '+T'
            : '';
    // other_rooms += 'undefined' !== typeof p.bedroom_studio && 1 == p.bedroom_studio ? '+St' : '';

    if (_.isEmpty(p.structure_type)) {
        return '';
    }

    return (
        ('land' !== p.structure_type
            ? 0 == p.bedroom_studio || 0 != p.bedroom_no
                ? p.bedroom_no + (short ? '' : ' Bedroom')
                : 'Studio'
            : '') + other_rooms
    );
}

export function structure(p) {
    if (_.isEmpty(p.structure_type)) {
        return '';
    } else {
        return p.structure_type.capitalize();
    }
}

export function size(p) {
    return _.isUndefined(p.size)
        ? ''
        : numeral(p.size).format('0,0.00') + 'm&sup2;';
}

export function structureSize(p) {
    return p.structure_size === null
        ? ''
        : numeral(p.structure_size).format('0,0.00') + 'm&sup2;';
}

export function landSize(p) {
    return p.land_size === null
        ? ''
        : numeral(p.land_size).format('0,0.00') + 'm&sup2;';
}

export function price(p, bottom_price = false) {
    let cost;

    switch (determineType(p)) {
        case 'sales':
            cost = p.price;
            break;
        case 'rent':
            cost = bottom_price ? p.bottom : p.rent;
            break;
        case 'monthly':
            cost = p.monthly_rate;
            break;
        case 'weekly':
            cost = p.weekly_rate;
            break;
        default:
            return '';
    }

    return amount(cost);
}

export function amount(amount) {
    return numeral(amount).format('0,0');
}

export function currency() {
    return '&yen;';
}

/* Generate the property ID */
export function propertyId(p) {
    let type, building;

    switch (determineType(p)) {
        case 'sales':
            type = 'S';
            break;
        case 'rent':
            type = 'R';
            break;
        case 'monthly':
            type = 'M';
            break;
        case 'weekly':
            type = 'W';
            break;
        default:
            type = 'X';
    }

    switch (p.structure_type) {
        case 'house':
            building = 'H';
            break;
        case 'building':
            building = 'B';
            break;
        case 'land':
            building = 'L';
            break;
        default:
            building = 'A';
    }

    return type + building + (p.id || '');
}

export function division(divisionId) {
    switch (divisionId) {
        case 0:
            return 'Other';
        case 1:
            return 'Leasing';
        case 2:
            return 'Sales';
        case 3:
            return 'PM';
        case 11:
            return 'Investment Sales';
        case 12:
            return 'Residential Hospitality';
        case 14:
            return 'Business Development';
        case 15:
            return 'Project Sales';
        case 16:
            return 'Project Management';
        case 17:
            return 'Trunk Room';
        case 18:
            return 'Marketing';
        case 20:
            return "Ken's Place";
        default:
            return 'No Mapping Yet';
    }
}

export function getFinancialYear(date) {
    if (!date) {
        date = moment();
    }

    return date.month() < 3 ? date.year() : date.year() + 1; // 3 = April
}

export function getName(person) {
    if (person === null) {
        return '';
    }

    let name = '';
    if (person.last_name) {
        name += person.last_name;
    }
    if (person.last_name && person.first_name) {
        name += ', ';
    }
    if (person.first_name) {
        name += person.first_name;
    }
    if (person.nickname) {
        name += ` (${person.nickname})`;
    }
    return name;
}

export function getCompanyName(company) {
    if (Preferences.company_name === 'en') {
        return company.company_name || company.company_name_ja;
    } else {
        return company.company_name_ja || company.company_name;
    }
}

export function getCompanyTel(company) {
    return (
        (Preferences.property_type === 'sales'
            ? company.sales_telephone
            : company.rent_telephone) || company.telephone
    );
}

export function getActivityMessageCreated(activity) {
    return `
    Client record for
    <a href="#people/edit/${activity.person.id}">
        ${getName(activity.person)}
    </a>
    was created
    `;
}

export function getActivityMessageLogin(activity) {
    return `
    <a href="#people/edit/${activity.person.id}">
        ${getName(activity.person)}
    </a>
    logged in
    `;
}

export function getActivityMessageInquiry(activity) {
    return `
    <a href="#people/edit/${activity.person.id}">
        ${getName(activity.person)}
    </a>
    sent a
    <a href="#inquiries/${activity.relatedData.inquiry.id}">
        ${activity.relatedData.inquiry.inquiry_type} enquiry
    </a>
    `;
}

export function getActivityMessagePropertyAlertCreate(activity) {
    if (
        activity.person &&
        (activity.person.last_name || activity.person.first_name)
    ) {
        return `
        <a href="#people/edit/${activity.person.id}">
            ${getName(activity.person)}
        </a>
        created a
        <a href="#property/alerts/edit/${activity.relatedData.propertyAlert.id}">
            property alert
        </a>
        `;
    } else {
        return `
        ${activity.relatedData.propertyAlert.email_address} created a
        <a href="#property/alerts/edit/${activity.relatedData.propertyAlert.id}">
            property alert
        </a>
        `;
    }
}

export function getActivityMessagePropertyAlertDelete(activity) {
    if (
        activity.person &&
        (activity.person.last_name || activity.person.first_name)
    ) {
        return `
        <a href="#people/edit/${activity.person.id}">
            ${getName(activity.person)}
        </a>
        deleted a
        <a href="#property/alerts/edit/${activity.relatedData.propertyAlert.id}">
            property alert
        </a>
        `;
    } else {
        return `
        ${activity.relatedData.propertyAlert.email_address} deleted a
        <a href="#property/alerts/edit/${activity.relatedData.propertyAlert.id}">
            property alert
        </a>
        `;
    }
}

export function getActivityMessagePropertyAlertOpen(activity) {
    if (
        activity.person &&
        (activity.person.last_name || activity.person.first_name)
    ) {
        return `
        <a href="#people/edit/${activity.person.id}">
            ${getName(activity.person)}
        </a>
        opened a
        <a href="#property/alerts/edit/${activity.relatedData.propertyAlert.id}">
            ${activity.relatedData.propertyAlert.criteria.property_type} property alert
        </a>
        `;
    } else {
        return `
        ${activity.relatedData.propertyAlert.email_address} opened a
        <a href="#property/alerts/edit/${activity.relatedData.propertyAlert.id}">
            ${activity.relatedData.propertyAlert.criteria.property_type} property alert
        </a>
        `;
    }
}

export function getActivityMessagePropertyAlertClick(activity) {
    if (
        activity.person &&
        (activity.person.last_name || activity.person.first_name)
    ) {
        return `
        <a href="#people/edit/${activity.person.id}">
            ${getName(activity.person)}
        </a>
        clicked a
        <a href="#property/alerts/edit/${activity.relatedData.propertyAlert.id}">
            ${activity.relatedData.propertyAlert.criteria.property_type} property alert
        </a>
        `;
    } else {
        return `
        ${activity.relatedData.propertyAlert.email_address} clicked a
        <a href="#property/alerts/edit/${activity.relatedData.propertyAlert.id}">
            ${activity.relatedData.propertyAlert.criteria.property_type} property alert
        </a>
        `;
    }
}

export function getActivityMessageClientAssigned(activity) {
    if (
        activity.person &&
        (activity.person.last_name || activity.person.first_name)
    ) {
        if (activity.division) {
            return `
            <a href="#people/edit/${activity.person.id}">
                ${getName(activity.person)}
            </a>
            was assigned to ${getName(activity.relatedData.staff)} (${activity.division.name})
            `;
        } else {
            return `
            <a href="#people/edit/${activity.person.id}">
                ${getName(activity.person)}
            </a>
            was assigned to ${getName(activity.relatedData.staff)}
            `;
        }
    } else {
        /** @todo Remove once we're sure that all clients have a name */
        return `
        <a href="#people/edit/${activity.person.id}">
            ${activity.person.email_address}
        </a>
        was assigned to ${getName(activity.relatedData.staff)}
        `;
    }
}

export function getActivityMessageClientUnassigned(activity) {
    if (
        activity.person &&
        (activity.person.last_name || activity.person.first_name)
    ) {
        if (activity.division) {
            return `
            <a href="#people/edit/${activity.person.id}">
                ${getName(activity.person)}
            </a>
            was unassigned from ${activity.division.name}
            `;
        } else {
            return `
            <a href="#people/edit/${activity.person.id}">
                ${getName(activity.person)}
            </a>
            was unassigned
            `;
        }
    } else {
        /** @todo Remove once we're sure that all clients have a name */
        return `
        <a href="#people/edit/${activity.person.id}">
            ${activity.person.email_address}
        </a>
        was unassigned
        `;
    }
}

export function getActivityMessageClientRelinked(activity) {
    if (activity.data.from_id) {
        return `
        Client was relinked from
        <a href="#people/edit/${activity.data.from_id}">
            ${activity.data.from_id}
        </a>
        `;
    } else if (activity.data.to_id) {
        return `
        Client was relinked to
        <a href="#people/edit/${activity.data.to_id}">
            ${activity.data.to_id}
        </a>
        `;
    } else {
        return `Client was relinked`;
    }
}

export function getAssignedStaff(criteria, person) {
    if (criteria.is_client === 'sales') {
        if (person.staff_sales) {
            return getName(person.staff_sales);
        } else {
            return '<span class="badge badge-danger">Unassigned</span>';
        }
    } else if (criteria.is_client === 'investment_sales') {
        if (person.staff_investment_sales) {
            return getName(person.staff_investment_sales);
        } else {
            return '<span class="badge badge-danger">Unassigned</span>';
        }
    } else if (criteria.is_client === 'leasing') {
        if (person.staff_leasing) {
            return getName(person.staff_leasing);
        } else {
            return '<span class="badge badge-danger">Unassigned</span>';
        }
    } else if (criteria.is_client === 'pm') {
        if (person.staff_pm) {
            return getName(person.staff_pm);
        } else {
            return '<span class="badge badge-danger">Unassigned</span>';
        }
    } else {
        return '-';
    }
}

export function determinePersonType(person) {
    const types = [];

    if (person.is_tenant) {
        types.push('Tenant');
    }

    if (person.is_owner) {
        types.push('Owner');
    }

    if (person.is_buyer) {
        types.push('Buyer');
    }

    if (person.is_seller) {
        types.push('Seller');
    }

    return types.join(', ');
}

export function criteriaBudget(person) {
    const output = [];

    if (person.is_tenant && person.rent_budget_high) {
        output.push(numeral(person.rent_budget_high).format('0,0'));
    }
    if (person.is_buyer && person.sales_budget_high) {
        output.push(numeral(person.sales_budget_high).format('0,0'));
    }

    return output.join(' / ');
}

const rentBedroomCriteriaFields = [
    'rent_bedroom_0',
    'rent_bedroom_1',
    'rent_bedroom_2',
    'rent_bedroom_3',
    'rent_bedroom_4',
];
const salesBedroomCriteriaFields = [
    'sales_bedroom_0',
    'sales_bedroom_1',
    'sales_bedroom_2',
    'sales_bedroom_3',
    'sales_bedroom_4',
];

function filterSelectedBedroomCriteria(criteriaFields, person) {
    const criteria = [];
    for (const field of criteriaFields) {
        if (person[field] === 1) {
            criteria.push(TextHelper._(field, 'criteria_bedrooms'));
        }
    }
    return criteria;
}

export function criteriaBedrooms(person) {
    const output = [];

    if (person.is_tenant) {
        const criteria = filterSelectedBedroomCriteria(
            rentBedroomCriteriaFields,
            person,
        );

        if (criteria.length > 0) {
            output.push(criteria.join(', '));
        }
    }
    if (person.is_buyer) {
        const criteria = filterSelectedBedroomCriteria(
            salesBedroomCriteriaFields,
            person,
        );

        if (criteria.length > 0) {
            output.push(criteria.join(', '));
        }
    }

    return output.join(' / ');
}

export function criteriaSize(person) {
    const output = [];

    if (person.is_tenant && person.rent_size_low) {
        output.push(numeral(person.rent_size_low).format('0,0'));
    }
    if (person.is_buyer && person.sales_size_low) {
        output.push(numeral(person.sales_size_low).format('0,0'));
    }

    return output.join(' / ');
}

export function criteriaStructureType(person) {
    const output = [];

    if (person.is_tenant && person.rent_structure_type) {
        output.push(TextHelper._(person.rent_structure_type, 'structure_type'));
    }
    if (person.is_buyer && person.sales_structure_type) {
        output.push(
            TextHelper._(person.sales_structure_type, 'structure_type'),
        );
    }

    return output.join(' / ');
}

export function nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag =
        is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
    return (str + '').replace(
        /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
        '$1' + breakTag + '$2',
    );
}

export function dealType(t) {
    switch (t) {
        case 2:
            return 'Leasing Deal';
        case 3:
            return 'Sales Deal';
        default:
            return '';
    }
}

export function hasFloorplan(p) {
    // Check p.images for floorplan
    if (_.isObject(_.findWhere(p.images, { type: 'floorplan' }))) {
        return true;
    }
    return false;
}

export function isFloorplan(img) {
    return img.type === 'floorplan';
}

export function hasImages(p) {
    // If removing floorplan from images still has at least 1 image
    if (_.reject(p.images, isFloorplan).length >= 1) {
        return true;
    }
    return false;
}

export function getCoverPhotoFilename(images) {
    // Get cover photo
    const coverPhoto = _.findWhere(images, { type: 'cover-photo' });
    if (coverPhoto) {
        return coverPhoto.filename;
    }

    // Get building photo
    const buildingPhoto = _.findWhere(images, { type: 'building' });
    if (buildingPhoto) {
        return buildingPhoto.filename;
    }

    return '';
}

export function getFloorplanFilename(images) {
    // Get floorplan
    const floorplan = _.findWhere(images, { type: 'floorplan' });
    if (floorplan) {
        return floorplan.filename;
    }

    return '';
}

export function sd(query, col) {
    return col == query.sort_key ? query.sort_order : '';
}

export function sc(query, col) {
    if (col == query.sort_key) {
        return (
            '<i class="fa ' +
            ('desc' === query.sort_order ? 'fa-arrow-up' : 'fa-arrow-down') +
            '"></i>'
        );
    }
}

export function monthsText(months) {
    if (months === null) {
        return '';
    } else if (Number(months) === 1) {
        return months + ' month';
    } else {
        return months + ' months';
    }
}

const countryCodes = {
    AF: 'Afghanistan',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    CV: 'Cabo Verde',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CD: 'Congo (Democratic Republic)',
    CG: 'Congo',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czechia',
    CI: "Côte d'Ivoire",
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    SZ: 'Eswatini',
    ET: 'Ethiopia',
    FK: 'Falkland Islands',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and McDonald Islands',
    VA: 'Holy See',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KR: 'Korea',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: "Lao People's Democratic Republic",
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    KP: 'North Korea',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestine',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    MK: 'Republic of North Macedonia',
    RO: 'Romania',
    RU: 'Russia',
    RW: 'Rwanda',
    RE: 'Réunion',
    BL: 'Saint Barthélemy',
    SH: 'Saint Helena, Ascension and Tristan da Cunha',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin (French part)',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten (Dutch part)',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan',
    TJ: 'Tajikistan',
    TZ: 'Tanzania',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    UM: 'United States Minor Outlying Islands',
    US: 'United States',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Viet Nam',
    VG: 'Virgin Islands (British)',
    VI: 'Virgin Islands (U.S.)',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    AX: 'Åland Islands',
};

export function countryName(countryCode) {
    return countryCodes[countryCode] ? countryCodes[countryCode] : '-';
}

export function feeType(type) {
    switch (type) {
        case 'general':
            return 'General';
        case 'buyer':
            return 'Buyer / Lessee';
        case 'seller':
            return 'Seller / Lessor';
        default:
            return '';
    }
}

export function paid(paid) {
    return paid ? 'Paid' : 'Unpaid';
}

export function getIndividualLink(datum) {
    return '#people/edit/' + datum.id;
}

export function getCompanyLink(datum) {
    return '#company/edit/' + datum.id;
}

export function revenueId(id) {
    return 'R-' + id;
}

export function dealId(id, customId) {
    if (id) {
        return 'D-' + id;
    } else if (customId) {
        return customId;
    } else {
        return '';
    }
}
