import { Model } from 'backbone';
import config from '../../config';

export default Model.extend({
    preinitialize(options) {
        this.alertId = options.alertId;
    },

    urlRoot: function () {
        return config.api.url + 'property_alert/' + this.alertId + '/emails';
    },
});
