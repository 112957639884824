import ModalView from '../../generic/modal';
import template from '../templates/alert-criteria.html';

export default class PropertyAlertCriteriaModalView extends ModalView {
    render() {
        console.debug('PropertyAlertCriteriaModalView#render');

        this.renderModal().modalContent.innerHTML = template({
            search_text: this.search_text,
        });

        return this;
    }
}
