import { Collection } from 'backbone';
import config from '../../config';

export default class PropertyAlertEmailEventsCollection extends Collection {
    initialize(models, options) {
        this.id = options.id;
    }

    url() {
        return config.api.url + 'property_alert_emails/' + this.id + '/events';
    }

    parse(response) {
        this.total_rows = response.total_rows;

        return response.results;
    }
}
